import React from 'react'
import { ContentWrapper } from '../components/common/wrappers/Wrappers'
import styled from 'styled-components'
import { SectionText } from '../components/common/typography/Typography'
import { breakpoint, color, transition } from '../configs/utilities'
import Layout from '../components/layout/Layout'

const Privacy_Policy = () => {
  return (
    <Layout pageName="privacy-policy">
      <ContentWrapper>
        <Wrapper>
          <Title>Privacy Policy for vhouse</Title>
  
          <SectionText>
            At vhouse.rs, accessible from www.vhouse.rs, one of our main priorities is the privacy of our visitors. This
            Privacy Policy document contains types of information that is collected and recorded by vhouse.rs and how we
            use it.
          </SectionText>
  
          <SectionText>
            If you have additional questions or require more information about our Privacy Policy, do not hesitate to
            contact us.
          </SectionText>
  
          <SectionText>
            This Privacy Policy applies only to our online activities and is valid for visitors to our website with
            regards to the information that they shared and/or collect in vhouse.rs. This policy is not applicable to any
            information collected offline or via channels other than this website. Our Privacy Policy was created with the
            help of the <a href="https://www.privacypolicygenerator.info/">Free Privacy Policy Generator</a>.
          </SectionText>
  
          <SectionText>
            <strong>Consent</strong>
          </SectionText>
  
          <SectionText>
            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
          </SectionText>
  
          <SectionText>
            {' '}
            <strong>Information we collect</strong>
          </SectionText>
  
          <SectionText>
            The personal information that you are asked to provide, and the reasons why you are asked to provide it, will
            be made clear to you at the point we ask you to provide your personal information.
          </SectionText>
          <SectionText>
            If you contact us directly, we may receive additional information about you such as your name, email address,
            phone number, the contents of the message and/or attachments you may send us, and any other information you
            may choose to provide.
          </SectionText>
          <SectionText>
            When you register for an Account, we may ask for your contact information, including items such as name,
            company name, address, email address, and telephone number.
          </SectionText>
  
          <SectionText>
            <strong>How we use your information</strong>
          </SectionText>
  
          <SectionText>We use the information we collect in various ways, including to:</SectionText>
  
          <ul>
            <li>
              <SectionText>Provide, operate, and maintain our website</SectionText>
            </li>
            <li>
              <SectionText>Improve, personalize, and expand our website</SectionText>
            </li>
            <li>
              <SectionText>Understand and analyze how you use our website</SectionText>
            </li>
            <li>
              <SectionText>Develop new products, services, features, and functionality</SectionText>
            </li>
            <li>
              <SectionText>
                Communicate with you, either directly or through one of our partners, including for customer service, to
                provide you with updates and other information relating to the website, and for marketing and promotional
                purposes
              </SectionText>
            </li>
            <li>
              <SectionText>Send you emails</SectionText>
            </li>
            <li>
              <SectionText>Find and prevent fraud</SectionText>
            </li>
          </ul>
  
          <SectionText>
            <strong>Log Files</strong>
          </SectionText>
  
          <SectionText>
            vhouse.rs follows a standard procedure of using log files. These files log visitors when they visit websites.
            All hosting companies do this and a part of hosting services' analytics. The information collected by log
            files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time
            stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that
            is personally identifiable. The purpose of the information is for analyzing trends, administering the site,
            tracking users' movement on the website, and gathering demographic information.
          </SectionText>
  
          <SectionText>
            <strong>Cookies and Web Beacons</strong>
          </SectionText>
  
          <SectionText>
            Like any other website, vhouse.rs uses 'cookies'. These cookies are used to store information including
            visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is
            used to optimize the users' experience by customizing our web page content based on visitors' browser type
            and/or other information.
          </SectionText>
  
          <SectionText>
            <strong>Advertising Partners Privacy Policies</strong>
          </SectionText>
  
          <SectionText>
            You may consult this list to find the Privacy Policy for each of the advertising partners of vhouse.rs.
          </SectionText>
  
          <SectionText>
            Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used
            in their respective advertisements and links that appear on vhouse.rs, which are sent directly to users'
            browser. They automatically receive your IP address when this occurs. These technologies are used to measure
            the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on
            websites that you visit.
          </SectionText>
  
          <SectionText>
            Note that vhouse.rs has no access to or control over these cookies that are used by third-party advertisers.
          </SectionText>
  
          <SectionText>
            <strong>Third Party Privacy Policies</strong>
          </SectionText>
  
          <SectionText>
            vhouse.rs's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to
            consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may
            include their practices and instructions about how to opt-out of certain options.{' '}
          </SectionText>
  
          <SectionText>
            You can choose to disable cookies through your individual browser options. To know more detailed information
            about cookie management with specific web browsers, it can be found at the browsers' respective websites.
          </SectionText>
  
          <SectionText>
            <strong>CCPA Privacy Rights (Do Not Sell My Personal Information)</strong>
          </SectionText>
  
          <SectionText>Under the CCPA, among other rights, California consumers have the right to:</SectionText>
          <ul>
            <li>
              <SectionText>
                Request that a business that collects a consumer's personal data disclose the categories and specific
                pieces of personal data that a business has collected about consumers.
              </SectionText>
            </li>
            <li>
              <SectionText>
                Request that a business delete any personal data about the consumer that a business has collected.
              </SectionText>
            </li>
            <li>
              <SectionText>
                Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
              </SectionText>
            </li>
            <li>
              <SectionText>
                If you make a request, we have one month to respond to you. If you would like to exercise any of these
                rights, please contact us.
              </SectionText>
            </li>
          </ul>
  
          <SectionText>
            <strong>GDPR Data Protection Rights</strong>
          </SectionText>
          <ul>
            <li>
              <SectionText>
                We would like to make sure you are fully aware of all of your data protection rights. Every user is
                entitled to the following:
              </SectionText>
            </li>
            <li>
              <SectionText>
                The right to access – You have the right to request copies of your personal data. We may charge you a
                small fee for this service.
              </SectionText>
            </li>
            <li>
              <SectionText>
                The right to rectification – You have the right to request that we correct any information you believe is
                inaccurate. You also have the right to request that we complete the information you believe is incomplete.
              </SectionText>
            </li>
            <li>
              <SectionText>
                The right to erasure – You have the right to request that we erase your personal data, under certain
                conditions.
              </SectionText>
            </li>
            <li>
              <SectionText>
                The right to restrict processing – You have the right to request that we restrict the processing of your
                personal data, under certain conditions.
              </SectionText>
            </li>
            <li>
              <SectionText>
                The right to object to processing – You have the right to object to our processing of your personal data,
                under certain conditions.
              </SectionText>
            </li>
            <li>
              <SectionText>
                The right to data portability – You have the right to request that we transfer the data that we have
                collected to another organization, or directly to you, under certain conditions.
              </SectionText>
            </li>
            <li>
              <SectionText>
                If you make a request, we have one month to respond to you. If you would like to exercise any of these
                rights, please contact us.
              </SectionText>
            </li>
          </ul>
  
          <SectionText>
            <strong>Children's Information</strong>
          </SectionText>
  
          <SectionText>
            Another part of our priority is adding protection for children while using the internet. We encourage parents
            and guardians to observe, participate in, and/or monitor and guide their online activity.
          </SectionText>
  
          <SectionText>
            vhouse.rs does not knowingly collect any Personal Identifiable Information from children under the age of 13.
            If you think that your child provided this kind of information on our website, we strongly encourage you to
            contact us immediately and we will do our best efforts to promptly remove such information from our records.
          </SectionText>
        </Wrapper>
      </ContentWrapper>
    </Layout>
  )
}

export default Privacy_Policy

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 20px;
  padding: 50px 20px;

  a {
    color: ${color.green};
    transition: ${transition.default};
    padding-left: 5px;
    &:hover {
      opacity: 0.7;
    }
  }

  ul {
    padding-left: 20px;
    @media screen and (min-width: ${breakpoint.md}) {
      padding-left: 50px;
    }
    li {
      margin-top: 10px;
    }
  }

  @media screen and (min-width: ${breakpoint.md}) {
    padding: 100px 5%;
  }
`

const Title = styled.h1`
font-weight: 400;
  font-size: 27px;
  line-height: 48px;
  color: ${color.white};
  margin-bottom: 20px;
  text-align: center;

  @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 8vw;
    line-height: 10vw;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 38px;
    margin-bottom: 40px;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    font-size: 46px;
    margin-bottom: 50px;
  }
`
